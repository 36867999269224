import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import useTheme from "../../layout/theme/useTheme"
import WaveImage from "../../svgs/wave2.svg"
import LetterIcon from "../../svgs/envelope-solid.svg"

import ContentRow from "../../components/ContentRow"

const Wrapper = styled.button`
  background: ${props => props.theme.color.background.darkBlue};
  width: 100%;
  border: none;
  padding: 0;
  padding-top: ${props => props.theme.spaces.l};
  color: white;
  transition: background 0.7s;
  margin: ${props => props.theme.spaces.stack.xl};
  cursor: pointer;

  :hover {
    background: ${props => props.theme.color.intoNumbers.darkBlue};
  }
`

const TextBlock = styled.div`
  font-family: "Inter UI", sans-serif;
  padding: ${props => props.theme.spaces.stack.xl};
  font-size: 16px;
`

function ContactPrompt() {
  const { spaces } = useTheme()
  return (
    <>
      <Link to="/kontakt">
        <Wrapper>
          <ContentRow>
            <TextBlock>
              <p style={{ fontSize: "22px", fontWeight: "600" }}>
                Kontaktieren Sie uns
              </p>
              <LetterIcon style={{ height: "35px", width: "35px" }} />
              <p>
                Schicken Sie uns Ihre Anfrage, wir freuen uns darauf Sie beraten
                zu d&uuml;rfen
              </p>
              <p>
                Oder rufen Sie gleich direkt an{" "}
                <span style={{ marginLeft: spaces.l, fontWeight: "900" }}>
                  <span style={{ marginRight: spaces.xs }}>Peter Just :</span>{" "}
                  +43 (0)720 701010
                </span>
              </p>
            </TextBlock>
          </ContentRow>
          <WaveImage
            style={{ height: "30px", width: "100%", marginBottom: "-4px" }}
          />
        </Wrapper>
      </Link>
    </>
  )
}

export default ContactPrompt

import React from "react"

import SEO from "../components/SEO"

import Layout from "../layout"

import IndexHead from "../containers/IndexHead"
import IndexMarketing from "../containers/IndexMarketing"
import ContactPrompt from "../containers/ContactPrompt"
import IndexReference from "../containers/IndexReference"

const IndexPage = () => (
  <>
    <Layout>
      <SEO
        title="Business Intelligence und Web Development"
        keywords={[
          `Into Numbers`,
          `Data Science`,
          `Web Development`,
          `Digitalisierung`,
          `Trainings`,
          `Business Intelligence`,
        ]}
        description="Wir begleiten Sie bei der Digitalisierung Ihrer Prozesse und unterstützen Sie im Wandel hin zum datenorientierten Unternehmen."
      />
      <IndexHead />
      <IndexMarketing />
      <ContactPrompt />
      <IndexReference />
    </Layout>
  </>
)

export default IndexPage

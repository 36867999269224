import React from "react"
import styled from "styled-components"

import Grafics from "./grafics"

import ContentRow from "../../components/ContentRow"

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;

  @media (max-width: ${props => props.theme.media.tablet}) {
    flex-direction: column;
  }
`

const Card = styled.div`
  max-width: 270px;
  display: flex;
  flex-direction: column;
  margin: ${props => props.theme.spaces.stack.xl};

  @media (max-width: ${props => props.theme.media.tablet}) {
    max-width: 100%;
  }
`

const CardHeading = styled.h3`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  margin: ${props => props.theme.spaces.stack.s};
`

const CardImage = styled.div`
  margin: 0 auto ${props => props.theme.spaces.m} auto;
  width: 250px;
  height: 200px;
`

const CardText = styled.div`
  text-align: justify;

  @media (max-width: ${props => props.theme.media.tablet}) {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
`
function IndexMarketing() {
  return (
    <ContentRow>
      <Wrapper>
        <Card>
          <CardHeading>Data Science</CardHeading>
          <CardImage>
            <Grafics
              name={"dataScience"}
              alt={"Into Numbers Data Science Graphic"}
            />
          </CardImage>
          <CardText>
            Data-Mining, Vorhersagemodelle (Artificial Intelligence) und Analyse
            von Daten sind eine wertvolle Grundlage f&uuml;r wichtige
            Entscheidungsfindungen. Lassen Sie uns gemeinsam Wissen aus Ihren
            Daten ziehen, diese f&uuml;r Vorhersagen und Entscheidungen nutzen,
            und somit gezielt und gewinnbringend f&uuml;r Sie einsetzen.
          </CardText>
        </Card>
        <Card>
          <CardHeading>Web Development</CardHeading>
          <CardImage style={{ width: "230px" }}>
            <Grafics
              name={"webDevelopment"}
              alt={"Into Numbers Web Development Graphic"}
            />
          </CardImage>
          <CardText>
            Wir designen und entwickeln Ihre Webanwendungen auf Basis derselben
            Technologien, welche auch von Facebook, Netflix und Dropbox
            verwendet werden. Durch den Einsatz modernster Cloud Technologien
            erreichen wir maximale Verf&uuml;gbarkeit und kosteneffiziente
            Skalierung, und machen so Ihre Gesch&auml;fts Idee f&uuml;r die Welt
            verf&uuml;gbar.
          </CardText>
        </Card>
        <Card>
          <CardHeading>Trainings</CardHeading>
          <CardImage>
            <Grafics
              name={"trainings"}
              alt={"Into Numbers Trainings Graphic"}
            />
          </CardImage>
          <CardText>
            Wir bieten Schulungen und Workshops in unseren Kernkompetenzen Data
            Science, der Wandel zum datenorientierten Unternehmen, sowie
            Blockchain an. Lernen Sie Daten als strategisches Gut zu behandeln,
            und erfahren Sie anhand konkreter Praxisbeispiele die
            M&ouml;glichkeiten der Digitalisierung f&uuml;r Ihr Unternehmen.
          </CardText>
        </Card>
      </Wrapper>

      <Wrapper />
    </ContentRow>
  )
}

export default IndexMarketing

import React from "react"
import styled from "styled-components"

import ContentRow from "../../components/ContentRow"

const Wrapper = styled(ContentRow)`
  margin-bottom: ${props => props.theme.spaces.l};
`
const Ask = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin: ${props => props.theme.spaces.stack.l};
`

const Us = styled.h1`
  max-width: 450px;
  line-height: 1.3;
  text-align: center;
  font-weight: 400;
  font-size: 28px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0 auto ${props => props.theme.spaces.l} auto;
`

const Details = styled.div`
  max-width: 600px;
  margin: 0 auto ${props => props.theme.spaces.xxxl} auto;
  text-align: center;
`

function IndexHead() {
  return (
    <Wrapper>
      <Ask>
        Sind Sie bereit f&uuml;r die Digitalisierung in Ihrem Unternehmen?
      </Ask>
      <Us>Business Intelligence und Web Development Start-Up aus Wien</Us>
      <Details>
        Daten sind der Rohstoff des Informations-Zeitalters. Der Einsatz von
        Daten-orientierten Produkten und L&ouml;sungen optimiert Ihre
        Gesch&auml;ftsprozesse und er&ouml;ffnet f&uuml;r Sie neue M&auml;rkte.
        Wir begleiten Sie bei der Digitalisierung Ihrer Prozesse und
        unterst&uuml;tzen Sie im Wandel hin zum datenorientierten Unternehmen.
      </Details>
    </Wrapper>
  )
}

export default IndexHead

import styled from "styled-components"

const SectionHeadline = styled.h2`
  font-family: "Inter UI", sans-serif;
  font-size: 26px;
  text-align: center;
  letter-spacing: 0.1em;
  font-weight: 400;
  margin: ${props => props.theme.spaces.stack.l};
  text-transform: uppercase;
`

export { SectionHeadline }

import React from "react"
import styled from "styled-components"

import ContentRow from "../../components/ContentRow"
import Logo from "./logo"
import { SectionHeadline } from "../../components/Headlines"

const Wrapper = styled.div`
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: ${props => props.theme.spaces.stack.xxl};
`

function IndexReference() {
  return (
    <ContentRow>
      <SectionHeadline>Referenzen</SectionHeadline>
      <Wrapper>
        <div style={{ width: "20%" }}>
          <Logo name={"speech"} alt={"Speech Processing Solutions Logo"} />
        </div>
        <div style={{ width: "20%" }}>
          <Logo name={"phycosera"} alt={"Phycosera Logo"} />
        </div>
        <div style={{ width: "10%" }}>
          <Logo name={"thinkaboutit"} alt={"Think About IT Logo"} />
        </div>
        <div style={{ width: "20%" }}>
          <Logo name={"xbody"} alt={"X Body Vienna EMS Next Level Logo"} />
        </div>
      </Wrapper>
    </ContentRow>
  )
}

export default IndexReference
